<i18n src="@/locales/basal_implant_risk.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="basal-implant-risk">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_1_1') }}
        </p>
        <p>
          {{ $t('article_1_2') }}
        </p>
        <ul>
          <li>{{ $t('li_1_3_1') }}</li>
          <li>{{ $t('li_1_3_2') }}</li>
          <li>{{ $t('li_1_3_3') }}</li>
        </ul>
        <p>
          {{ $t('article_1_4') }}
        </p>
      </ImanHomeSection>
      <ImanHomeSection :img="img[1]">
        <ImanH3>{{ $t('h3_title_1') }}</ImanH3>
        <p>
          {{ $t('article_2_1') }}
        </p>
        <p>
          {{ $t('article_2_2') }}
        </p>
        <ImanMoreInfo :path="$t('more_info_path_1')">
          {{ $t('more_info_1') }}
        </ImanMoreInfo>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-half">
          <ImanVideo :video="video" />
        </div>
      </div>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <p>
          {{ $t('article_3_1') }}
        </p>
        <span v-html="$t('article_3_2')" />
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_3') }}
        </h3>
        <p>
          {{ $t('article_4_1') }}
        </p>
        <p>
          {{ $t('article_4_2') }}
        </p>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_4') }}
        </h3>
        <p>
          {{ $t('article_5_1') }}
        </p>
        <p>
          {{ $t('article_5_2') }}
        </p>
        <p>
          {{ $t('article_5_3') }}
        </p>
        <span v-html="$t('article_5_4')" />
        <p>
          {{ $t('article_5_5') }}
        </p>
        <span v-html="$t('article_5_6')" />
        <ImanMoreInfo :path="$t('more_info_path_2')">
          {{ $t('more_info_2') }}
        </ImanMoreInfo>
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_5') }}
        </h3>
        <p>
          {{ $t('article_6_1') }}
        </p>
        <p>
          {{ $t('article_6_2') }}
        </p>
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard :title="$t('collapse_title_1')">
          <p>
            {{ $t('collapse_p1') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_2')">
          <p>
            {{ $t('collapse_p2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_3')">
          <p>
            {{ $t('collapse_p3') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_4')">
          <p>
            {{ $t('collapse_p4_1') }}
          </p>
          <p>
            {{ $t('collapse_p4_2') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_5')">
          <p>
            <span v-html="$t('collapse_p5')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_6')">
          <p>
            {{ $t('collapse_p6') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_7')">
          <p>
            {{ $t('collapse_p7') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_8')">
          <p>
            <span v-html="$t('collapse_p8_1')" />
          </p>
          <p>
            <span v-html="$t('collapse_p8_2')" />
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard :title="$t('collapse_title_9')">
          <p>
            <span v-html="$t('collapse_p9')" />
          </p>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'BasalImplantRisk',
    components: {
      ImanMoreInfo,
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        mainBackgroundClass: 'none-background',
        articlesCardContentId: [223, 612, 53, 84],
        img: [
          {
            path: 'dental-implant/basal-implant/risk/risques-implants-basaux.jpg',
            position: 'right',
            alt: 'Dangers de l\'implant basal ?'
          },
          {
            path: 'dental-implant/basal-implant/risk/etudes-implant-basal.jpg',
            position: 'right',
            alt: 'études et recul sur l\'implant basal'
          }
        ],
        video: {
          src: 'https://www.youtube.com/embed/0XIpp990Cww'
        }
      }
    },
    computed: {
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../../assets/img/thumbnail/MINIATURE_2.2.3.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/risk/H1-risque-implant-basal-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/risk/H1-risque-implant-basal-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../../../assets/img/dental-implant/basal-implant/risk/H1-risque-implant-basal-m.jpg");
    }
  }
</style>

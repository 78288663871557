import { render, staticRenderFns } from "./BasalImplantRisk.vue?vue&type=template&id=1cc4a515&scoped=true"
import script from "./BasalImplantRisk.vue?vue&type=script&lang=js"
export * from "./BasalImplantRisk.vue?vue&type=script&lang=js"
import style0 from "./BasalImplantRisk.vue?vue&type=style&index=0&id=1cc4a515&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cc4a515",
  null
  
)

/* custom blocks */
import block0 from "@/locales/basal_implant_risk.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Fbasal-dental-implant%2Frisk%2FBasalImplantRisk.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Fbasal-dental-implant%2Frisk%2FBasalImplantRisk.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports